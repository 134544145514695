import * as React from "react"
import PageTemplate from "../components/pageTemplate"
import Link from "../components/link"
import routes, { getLocalizedRoute, LocationContext } from "../routes"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import homeBanner from "../assets/images/GCCI-UniGraz_Img_c_EUM04_NASA02_USGS91_KLGA02_WEGC04.png"
import SEO from "../components/seo"

const Home = ({ location }: {
  location: any
}) => {
  const intl = useIntl()

  return (
    <LocationContext.Provider value={location}>
      <SEO title="GCCI ClimateTracer" />
      <PageTemplate title="Welcome to the Graz Climate Change Indicators portal GCCI.Earth|ClimateTracer.Earth!">
        {intl.locale === "de" ? <DeHome /> : <EnHome />}
      </PageTemplate>
    </LocationContext.Provider>
  )
}

const EnHome = ({}) => {
  const intl = useIntl()
  const wegcLink = "https://wegcenter.uni-graz.at/en/"
  const uniGrazLink = "https://www.uni-graz.at/en/"
  const climateChangeGrazLink = "https://climate-change.uni-graz.at/en/"

  const carbManageUrl = "https://wegcwp.uni-graz.at/carbmanage/"

  return (
    <div style={{ display: "contents" }}>
      <img src={homeBanner} alt="homeBanner" width="100%" />
      <p style={{ marginTop: "25px", fontWeight: "bolder" }}>
        {"Welcome to GCCI.Earth|ClimateTracer.Earth!"}
      </p>
      <p>
        This data portal is powered by the{" "}
        <Link href={wegcLink}>Wegener Center</Link> of the{" "}
        <Link href={uniGrazLink}>
          <FormattedMessage id={"uniGraz"} />
        </Link>{" "}
        on behalf of its Field of Excellence{" "}
        <Link href={climateChangeGrazLink}>Climate Change Graz</Link>. The
        current version is GCCIv2.3. More content and features are released
        on a regular basis.
      </p>
      <p>
        GCCI.Earth|ClimateTracer.Earth provides reliable{" "}
        <b>
          recent-past monitoring information jointly with current-state
          nowcasting and Paris-compliant future projection information
        </b>
        , over the critical climate change timeframe from 1960 via the present
        to 2050. In doing so, it focuses on <b>greenhouse gas emissions</b>{" "}
        ("Emissions"), <b>global warming</b> ("Warming"), and climate change
        impacts in terms of <b>weather and climate extremes</b> ("Extremes";
        released spring 2025). For further intro see this{" "}
        {
          <Link href={"https://doi.org/10.5194/egusphere-egu24-12998"}>
            {"Summary"}
          </Link>
        }{" "}
        and{" "}
        {
          <Link
            href={"/docs/Kirchengast-Pichler_EGU-2024-GCCIPres_Apr2024.pdf"}
          >
            {"Presentation"}
          </Link>
        }
        .
      </p>
      <p>
        Dive in through the Menu on top or right here:{" "}
        {
          <Link href={getLocalizedRoute(routes.gemGlo, intl)}>
            Emissions - Global
          </Link>
        }
        ,{" "}
        {
          <Link href={getLocalizedRoute(routes.gemEur, intl)}>
            Emissions - Europe
          </Link>
        }
        ,{" "}
        {
          <Link href={getLocalizedRoute(routes.gemAt, intl)}>
            Emissions - Austria
          </Link>
        }
        ,{" "}
        {
          <Link href={getLocalizedRoute(routes.cwmGlo, intl)}>
            Warming - Global
          </Link>
        }
        ,{" "}
        {
          <Link href={getLocalizedRoute(routes.ewmEur, intl)}>
            Extremes - Europe
          </Link>
        }
        ,{" "}
        {
          <Link href={getLocalizedRoute(routes.ewmEur, intl)}>
            Extremes - Austria
          </Link>
        }
        . The charts are generally made to be self-explanatory - for a one-stop
        overview of the current content, including detailed data-source
        references, see the{" "}
        <Link href={"https://gcci-documentation.gitbook.io"}>
          GCCI documentation
        </Link>
        .
      </p>
      <p>
        Welcome to visit also{" "}
        {<Link href={carbManageUrl}>CarbManage.Earth</Link>}, for which this
        data portal provides essential background and context information. Learn
        there on the new climate solutions framework "Carbon Management -
        carbsmart2Paris" that empowers actors at all public, institutional and
        personal levels to contribute GHG emission reductions compliant with the
        Paris climate goals.
      </p>
      <p>The portal is repeatedly updated – stay tuned.</p>
    </div>
  )
}

const DeHome = ({}) => {
  const intl = useIntl()
  const wegcLink = "https://wegcenter.uni-graz.at/de/"
  const uniGrazLink = "https://www.uni-graz.at/de/"
  const climateChangeGrazLink = "https://climate-change.uni-graz.at/de/"

  const carbManageUrl = "https://wegcwp.uni-graz.at/carbmanage/de/cm-de/"

  return (
    <div style={{ display: "contents" }}>
      <img src={homeBanner} alt="homeBanner" width="100%" />
      <p style={{ marginTop: "25px", fontWeight: "bolder" }}>
        Willkommen auf GCCI.Earth|ClimateTracer.Earth!
      </p>
      <p>
        Dieses Datenportal wird vom <Link href={wegcLink}>Wegener Center</Link>{" "}
        der <Link href={uniGrazLink}>Uni Graz</Link> im Rahmen ihres
        Profilbereichs{" "}
        <Link href={climateChangeGrazLink}>Climate Change Graz</Link> betrieben.
        Die aktuelle Version ist GCCIv2.3. Weitere Inhalte und Features werden
        regelmäßig hinzugefügt.
      </p>
      <p>
        GCCI.Earth|ClimateTracer.Earth bietet verlässliche{" "}
        <b>
          Monitoring-Informationen zur jüngsten Vergangenheit zusammen mit
          aktuellen Prognose-Informationen und Paris-konformen
          Zukunftsprojektionen
        </b>
        , über den kritischen Zeitraum von 1960 über die Gegenwart bis 2050.
        Dabei liegt der Fokus auf <b>Treibhausgas-Emissionen</b> ("Emissionen"),
        der <b>Globalen Erwärmung</b> ("Erwärmung") und den Auswirkungen des
        Klimawandels in Form von <b>Wetter- und Klimaextremen</b> ("Extreme";
        Release im Frühjahr 2025).
      </p>
      <p>
        Steigen Sie über das Menü oben oder gleich hier ein:{" "}
        <Link href={getLocalizedRoute(routes.gemGlo, intl)}>
          Emissionen - Global
        </Link>
        ,{" "}
        <Link href={getLocalizedRoute(routes.gemEur, intl)}>
          Emissionen - Europa
        </Link>
        ,{" "}
        <Link href={getLocalizedRoute(routes.gemAt, intl)}>
          Emissionen - Österreich
        </Link>
        ,{" "}
        <Link href={getLocalizedRoute(routes.cwmGlo, intl)}>
          Erwärmung - Global
        </Link>
        ,{" "}
        <Link href={getLocalizedRoute(routes.ewmEur, intl)}>
          Extreme - Europa
        </Link>
        ,{" "}
        <Link href={getLocalizedRoute(routes.ewmAt, intl)}>
          Extreme - Österreich
        </Link>
        . Die Charts sind weitgehend selbsterklärend dargestellt – für einen
        One-Stop-Überblick zum aktuellen Inhalt, einschließlich detaillierter
        Quellenangaben, siehe die{" "}
        <Link href="https://gcci-documentation.gitbook.io">
          GCCI-Dokumentation
        </Link>
        .
      </p>
      <p>
        Besuchen Sie auch <Link href={carbManageUrl}>CarbManage.Earth</Link>,
        für das dieses Datenportal wesentliche Hintergrund- und
        Kontextinformationen bietet. Erfahren Sie dort über "Carbon Management -
        carbsmart2Paris", einen neuen Lösungsansatz für wirksamen Klimaschutz,
        der Akteure weltweit auf allen öffentlichen, institutionellen und
        persönlichen Ebenen befähigt, selber erfolgreich und fair zum Abbau von
        Treibhausgasemissionen im Einklang mit den Pariser Klimazielen
        beizutragen.
      </p>
      <p>Das Portal wird wiederholt aktualisiert – bleiben Sie dran.</p>
    </div>
  )
}

export default Home
